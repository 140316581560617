import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import PhotoCameraRoundedIcon from "@mui/icons-material/PhotoCameraRounded";
import CircularProgress from "@mui/material/CircularProgress";
import DishesList from "./DishesList";
import Resizer from "react-image-file-resizer";

function App() {
  const [source, setSource] = useState("");
  const [jsonArray, setJsonArray] = useState("");
  const [alreadyUploaded, setAlreadyUploaded] = useState("");
  const [waitingForModel, setWaitingForModel] = useState("");
  const [error, setError] = useState("");

  const handleCapture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        Resizer.imageFileResizer(file, 800, 600, "png", 100, 0, (uri) => {
          const base64DataFixed = uri.slice(22);
          setSource(base64DataFixed);
        });
        setAlreadyUploaded(true);
      }
    }
  };

  const callGenerateModel = async () => {
    setWaitingForModel(true);
    try {
      let serverless_base_url;
      if (process.env.NODE_ENV === "development") {
        serverless_base_url = "http://localhost:3000/";
      } else {
        serverless_base_url = "https://nomad-price.sucunt.com/";
      }

      const response = await fetch(
        serverless_base_url + "api/serverless_function",
        {
          method: "POST", // Set the request method to POST
          headers: { "Content-Type": "application/json" }, // Set content type header
          body: JSON.stringify({ imageBase64: source }), // Send data in the request body
        }
      );
      const resultJson = await response.json();
      setJsonArray(resultJson);
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
    setWaitingForModel(false);
    setAlreadyUploaded(false);
  };

  const renderMenuTable = () => {
    if (error) {
      return <div>{error}</div>;
    }
    if (!jsonArray) {
      return <div></div>;
    }
    const data = jsonArray;

    return <DishesList dishes={data} />;
  };

  const renderUploadAndGenerateButtons = () => {
    if (alreadyUploaded) {
      return (
        <div>
          <p>The picture has been uploaded!</p>
          <button
            style={{
              fontSize: "16px",
              background: "#007bff",
              color: "white",
              padding: "8px",
              borderColor: "#007bff",
              borderRadius: ".25rem",
              fontWeight: "400",
            }}
            onClick={() => callGenerateModel()}
          >
            Get prices in dollar
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <p>Scan the menu to see prices in dollars and the menu in English</p>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={(e) => handleCapture(e.target)}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCameraRoundedIcon fontSize="large" color="primary" />
            </IconButton>
          </label>
        </div>
      );
    }
  };

  if (waitingForModel) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress
          color="secondary"
          size="lg"
          style={{ height: "100px", width: "100px" }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        textAlign: "center",
        backgroundColor: "#FCFCFC",
        padding: "16px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <h2>Nomad Price</h2>
          {renderUploadAndGenerateButtons()}
          {renderMenuTable()}
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
