import React from 'react';
import DishCard from './DishCard';
import './DishesList.css';

const DishesList = ({ dishes }) => {
  return (
    <div className="dishes-list">
      {dishes.map((dish, index) => (
        <DishCard 
            key={index} 
            name={dish['name']}
            ingredientsEN={dish['ingredients']}
            priceUSD={dish['price_usd']}
            ingredientsLocal={dish['ingredients_original']}
            priceLocal={dish['price_mxn']}
        />
      ))}
    </div>
  );
};

export default DishesList;