import React from 'react';
import './DishCard.css';

const DishCard = ({ name, ingredientsEN, priceUSD, ingredientsLocal, priceLocal }) => {
    return (
    <div className="dish-card">
      <div className="dish-name">{name}</div>
      <div className="english-info">
        <p className="left-element">{ingredientsEN}</p>
        <p className="right-element">${priceUSD}</p>
      </div>
      <div className="local-info">
        <p className="left-element">{ingredientsLocal}</p>
        <p className="right-element">M${priceLocal}</p>
      </div>
    </div>
  );
};

export default DishCard;